// Base
$none: 0;
$tiny: 1px;
$xxxxs: 2px;
$xxxs: 3px;
$xxs: 7px;
$xs: 10px;
$sm: 15px;
$md: 20px;
$lg: 25px;
$xl: 30px;
$xxl: 40px;
$xxxl: 60px;

// Specific
$border-width: $tiny;
$border-radius: 3px;

$default-form-width: 500px;

$default-footer-height: 60px;

$checkbox-offset: $xl + $xxs;

//$messenger-width should match the MSG_DRAWER_WIDTH in messenger/constants.tsx
$messenger-width: 450px;
$messenger-header-height: 47px;
$messenger-control-input-width: 200px;

$employee-form-view-padding: $xs $xxl;

$default-max-width: 1100px;
$nav-height: 48px;
$topbanner-nav-height: 75px;
$messenger-nav-height: 58px;
$message-drawer-offset: $nav-height + $messenger-nav-height;

$user-referrals-box-content-width: 260px;
$user-referrals-box-content-height: 278px;

$sidebar-width: 48px;

// Base 4
$s0: 0;
$s1: 1px;
$s2: 2px;
$s4: 4px;
$s8: 8px;
$s12: 12px;
$s16: 16px;
$s20: 20px;
$s24: 24px;
$s32: 32px;
$s36: 36px;
$s48: 48px;
$s64: 64px;
